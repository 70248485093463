const pageOptions = {
    defaultCurrent: 1,
    pageSize: 20,
    pageSizeOptions: ['10', '20', '30', '50'],
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: total => `共${total}条`
};

import tool from '@/utils/tool'

const MixinList = function (parseData, parsePage) {
    parseData = parseData || function (data) {
        return data;
    }
    return {
        data() {
            return {
                title: '',
                loading: false,
                dataSource: [],
                pageOptions,
                queryParam: {
                    page: 1,//第几页
                    limit: 20//每页中显示数据的条数
                },
                visible: false,
                editVisible: false,
                confirmLoading: false,
                objEdit: {},
                isGet: true
            }
        },
        components: {
            // headBtn
        },
        mounted() {
            this.title = this.$route.name
            this.queryParam.page = 1
            tool.$on('search', (res) => {
                // console.log('table1',res)

                // if(res.search){
                //   this.queryParam.search=res.search
                // }
                // if(res.month){
                //   this.queryParam.month=res.month
                // }
                this.queryParam = res
                this.queryParam.page = 1
                this.getData()
            })

            tool.$on('clear', (res) => {
                this.queryParam.page = 1
                delete this.queryParam.search;
                this.getData()
            })

            if (this.isGet) {
                this.getData()
            }
        },
        methods: {
            async getData() {
                this.loading = true
                let resp = null
                resp = await this.$api.get(this.dataUrl, {params: this.queryParam})

                this.loading = false
                this.dataSource = [];
                if (resp.code == 200) {
                    this.dataSource = parseData.call(this, resp.data);
                    const pagination = {...this.pageOptions}
                    pagination.total = parsePage ? parsePage.call(this, resp.data) : this.dataSource.length;
                    this.pageOptions = pagination
                } else this.$message.error(resp.message)
            },
            handleTableChange(pagination, filters, sorter) {
                console.log('sorter', sorter)
                this.pageOptions.current = pagination.current
                this.pageOptions.pageSize = pagination.pageSize
                this.queryParam.page = pagination.current
                this.queryParam.limit = pagination.pageSize
                console.log(filters, sorter)
                if (sorter) {
                    this.queryParam.order_by = sorter.field
                    if (sorter.order == 'ascend') {
                        this.queryParam.sort = 'asc'
                    } else if (sorter.order == 'descend') {
                        this.queryParam.sort = 'desc'
                    } else {
                        delete this.queryParam.order_by;
                        delete this.queryParam.sort;
                    }
                }

                this.getData()
            },
            toEdit(row) {
                this.editVisible = true;
                this.objEdit = JSON.parse(JSON.stringify(row));
            },
            toDelete(row) {
                this.$api
                    .post(this.delUrl, {id: row.id})
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$message.success('操作成功');
                            this.getData()
                        } else {
                            this.$message.error(resp.message);
                        }
                    })
                    .catch((e) => {
                        throw e;
                    });
            },
            onSearch(keyword) {
                this.queryParam.page = 1
                this.queryParam.keyword = keyword
                if (keyword == '') {
                    delete this.queryParam.keyword;
                    this.queryParam.page = 1
                    this.pageOptions.current = 1
                }
                this.getData()
            },
            onClear() {
                delete this.queryParam.search;
                this.queryParam.page = 1
                this.getData()
            },
            changeMonth(date) {
                this.title = date
                this.queryParam.page = 1
                this.queryParam.date = date.replace('-', '')
                if (date == '') {
                    delete this.queryParam.date;
                    this.queryParam.page = 1
                    this.pageOptions.current = 1
                }
                this.getData()
            },
            onSetNull() {
                this.loading = false
                this.queryParam.page = 1
                this.dataSource = [];
            }


        }
    }
}


export {MixinList}
