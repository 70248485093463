<template>
    <div class="star-bill-container">
        <le-card width="100%" height="600px" class="card">
            <!--   标题   -->
            <div class="card-title" style="margin: 0">自助下单</div>
            <!--   第一栏   -->
            <div class="box">
                <div class="left">
                    <span class="label">开放申请星光推流时间：每日{{ env.start_time }}点-{{ env.end_time }}点</span>
                    <a-button type="primary" style="margin-left: 18px" @click="visible.time=true">时间设置</a-button>
                    <a-button type="primary" style="margin-left: 12px" @click="visible.star=true">根据流水范围发放星光设置
                    </a-button>
                </div>
                <div class="right">
                    <a-radio-group
                            v-model="dateSelect"
                            button-style="solid"
                            style="margin-right: 20px"
                            @change="handleDateRadioChange"
                    >
                        <a-radio-button value="a">
                            今日
                        </a-radio-button>
                        <a-radio-button value="b">
                            昨日
                        </a-radio-button>
                        <a-radio-button value="c">
                            近七日
                        </a-radio-button>
                    </a-radio-group>
                    <a-range-picker
                            style="width: 280px"
                            v-model="dateRange"
                            @change="handleDatePickerChange"
                    />
                </div>
            </div>
            <!--   第二栏   -->
            <div class="query-box">
                <div class="left">
                    <span class="title">主播昵称/UID</span>
                    <a-input
                            class="input"
                            v-model="queryParam.keyword"
                            placeholder="请输入主播昵称/UID"
                            style="width: 200px"
                    />
                    <span class="title">直播间</span>
                    <a-input
                            class="input"
                            v-model="queryParam.room_id"
                            placeholder="请输入直播间"
                            style="width: 200px"
                    />
                    <span class="title">下单状态</span>
                    <a-select
                            class="input"
                            v-model="billStatus"
                            style="width: 119px"
                            @change="handleBillStatus"
                    >
                        <a-select-option value="success">
                            成功
                        </a-select-option>
                        <a-select-option value="fail">
                            失败
                        </a-select-option>
                        <a-select-option value="all">
                            全部
                        </a-select-option>
                    </a-select>
                    <span class="title">禁用状态</span>
                    <a-select
                            v-model="disableStatus"
                            style="width: 119px"
                            @change="handleDisableStatus"
                    >
                        <a-select-option value="enabled">
                            未禁用
                        </a-select-option>
                        <a-select-option value="disabled">
                            已禁用
                        </a-select-option>
                        <a-select-option value="all">
                            全部
                        </a-select-option>
                    </a-select>
                </div>
                <div class="right">
                    <a-button type="primary" @click="query">查询</a-button>
                    <a-button @click="reset">重置</a-button>
                </div>
            </div>
            <!--   表格   -->
            <div class="table-box">
                <a-table
                        :columns="columns"
                        :data-source="dataSource"
                        :pagination="pageOptions"
                        @change="handleTableChange"
                        style="width: 100%"
                >
                    <!--    头像      -->
                    <template v-slot:face="face">
                        <a-avatar :src="face"/>
                    </template>
                    <!--    UID      -->
                    <template v-slot:uid="uid">
                        <div style="text-align: center">
                            <a target="_blank" :href="'https://space.bilibili.com/'+uid[1]"><span>{{ uid[0] }}<br/>uid:{{
                                uid[1]
                                }}</span></a>
                        </div>
                    </template>
                    <!--    直播间      -->
                    <template v-slot:room="room">
                        <a target="_blank" :href="'https://live.bilibili.com/'+room">
                            <span>{{ room }}</span>
                        </a>
                    </template>
                    <!--    操作      -->
                    <template v-slot:op="_,row">
                        <a @click="handleLiveRecord(row.uid)">开播记录</a>
                        &emsp;
                        <a
                                v-if="row.disable === '未封禁'"
                                style="color: red"
                                @click="applyChange('/v2/autoSendStar/disableAutoSendStar',row.id,()=>row.disable = '已封禁');"
                        >禁用权限</a>
                        <a
                                v-else
                                style="color: lightgreen"
                                @click="applyChange('/v2/autoSendStar/notDisableAutoSendStar',row.id,()=>row.disable = '未封禁');"
                        >解除禁用</a>
                    </template>
                </a-table>
            </div>
        </le-card>

        <TimeDialog
                v-model="visible.time"
                :start.sync="env.start_time"
                :end.sync="env.end_time"
        />

        <ProvideStarDialog
                v-model="visible.star"
                :data-source.sync="env.conf"
        />

        <LiveRecordDialog
                v-if="visible.record"
                v-model="visible.record"
                :uid="recordId"
        />
    </div>
</template>

<script>
import TimeDialog from "@/components/star/bill/TimeDialog.vue";
import ProvideStarDialog from "@/components/star/bill/ProvideStarDialog.vue";
import {MixinList} from '@/common/v2/mixin';
import LiveRecordDialog from "@/components/star/bill/LiveRecordDialog.vue";
import moment from "moment";

const columns = [
    {
        title: '头像',
        dataIndex: 'face',
        key: 'face',
        align: 'center',
        scopedSlots: {customRender: 'face'}
    },
    {
        title: '昵称/UID',
        dataIndex: 'uid',
        key: 'uid',
        align: 'center',
        scopedSlots: {customRender: 'uid'}
    },
    {
        title: '直播间',
        dataIndex: 'room',
        key: 'room',
        align: 'center',
        scopedSlots: {customRender: 'room'}
    },
    {
        title: '申请时间',
        dataIndex: 'time',
        key: 'time',
        align: 'center',
        scopedSlots: {customRender: 'time'},
        sorter: (a, b) => Date.parse(a) - Date.parse(b),
    },
    {
        title: '近七日流水（人民币）',
        dataIndex: 'turnover',
        key: 'turnover',
        align: 'center',
    },
    {
        title: '发放数量',
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
    },
    {
        title: '下单状态',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
    },
    {
        title: '封禁状态',
        dataIndex: 'disable',
        key: 'disable',
        align: 'center',
    },
    {
        title: '操作',
        dataIndex: 'op',
        key: 'op',
        align: 'center',
        scopedSlots: {customRender: 'op'}
    },
];

const mixinList = new MixinList(
    function (_data) {
        let {list} = _data;
        delete _data.list;
        Object.assign(this.env, _data);
        return list.data.map(item => ({
            id: item.id,
            face: item.uface,
            uid: [item.uname, item.bili_id],
            room: item.room_id,
            time: item.begin_at,
            turnover: item.week_turnover,
            amount: item.star_num,
            status: item.is_send_success ? '成功' : '失败',
            disable: item.is_disable ? '已封禁' : '未封禁',
        }));
    },
    function (data) {
        return data.list.total;
    }
)

export default {
    name: "Index",
    components: {LiveRecordDialog, ProvideStarDialog, TimeDialog},
    data() {
        return {
            dataUrl: '/v2/autoSendStar/autoSendStarList',
            visible: {
                time: false,
                star: false,
                record: false,
            },
            columns,
            env: {
                conf: [{
                    start: '',
                    end: '',
                    star: '',
                }],
                start_time: 0,
                end_time: 0,
            },
            dateSelect: 'a',
            dateRange: [moment(), moment()],
            billStatus: 'all',
            disableStatus: 'all',
            recordId: ['', 0],
        }
    },
    mixins: [mixinList],
    methods: {
        query() {
            console.log(this.queryParam);
            this.getData();
        },
        reset() {
            this.queryParam = {
                page: this.queryParam.page,
                limit: this.queryParam.limit,
            }
            Object.assign(this, {
                dateSelect: 'a',
                billStatus: 'all',
                disableStatus: 'all',
            })
            this.handleDateRadioChange();
            this.getData();
        },
        applyChange(url, id, callback) {
            this.$api.post(url, {
                id,
            }).then(data => {
                callback();
                let {code, message} = data;
                if (code === 200) {
                    this.$message.success(message);
                } else {
                    this.$message.error(message)
                }
            }).catch(e => {
                throw e;
            });
        },
        handleDateRadioChange() {
            let start_time = moment(), end_time = moment();
            switch (this.dateSelect) {
                //昨日
                case 'b':
                    start_time.subtract(1, "days");
                    end_time.subtract(1, "days")
                    break;
                case 'c':
                    start_time.subtract(6, "days");
                    break;
            }
            this.dateRange = [
                start_time,
                end_time,
            ];
            this.handleDatePickerChange();
        },
        handleDatePickerChange() {
            this.queryParam.start_time = this.dateRange[0]?.format('YYYY-MM-DD 00:00:00') ?? undefined;
            this.queryParam.end_time = this.dateRange[1]?.format('YYYY-MM-DD 23:59:59') ?? undefined;
            console.log(this.dateRange)
            this.query();
        },
        handleBillStatus(value) {
            this.queryParam.is_send_success = {
                all: undefined,
                success: 1,
                fail: 0,
            }[value];
        },
        handleDisableStatus(value) {
            this.queryParam.is_disable = {
                all: undefined,
                disabled: 1,
                enabled: 0,
            }[value];
        },
        handleLiveRecord(id) {
            this.recordId = id;
            this.visible.record = true;
        },
    },
    beforeMount() {
        let now = moment();
        this.queryParam.start_time = now.format('YYYY-MM-DD 00:00:00');
        this.queryParam.end_time = now.format('YYYY-MM-DD 23:59:59');
    }
}
</script>

<style lang="less" scoped>
.star-bill-container {

  a {
    text-decoration: underline;
  }

  .title {
    margin-right: 12px;
  }

  .input {
    margin-right: 19px;
  }

  .card {
    padding: 0 30px;

    min-width: 1100px;
  }

  .card > div {
    margin-top: 20px;
    margin-bottom: 20px;

    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .query-box {
    .right > * {
      margin-right: 10px;
    }
  }

  .table-box {

  }
}
</style>
