<template>
  <a-modal
      :visible="value"
      title="时间设置"
      @ok="handleOk"
      @cancel="handleCancel"
      :closable="false"
      :mask-closable="false"
      centered
  >
    <a-form>
      <a-form-item class="center" label="开始时间">
        <a-input-number
            v-model="start"
            :min="0"
            :max="23"
        />
      </a-form-item>
      <a-form-item class="center" label="结束时间">
        <a-input-number
            v-model="end"
            :min="0"
            :max="23"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: "TimeDialog",
  props: ['value', 'start', 'end'],
  data() {
    return {}
  },
  methods: {
    handleOk() {
      if (this.start >= this.end) {
        this.$message.warn("开始时间必须早于结束时间");
        return;
      }
      this.$api.post('/v2/autoSendStar/setTime', {
        start_time: this.start,
        end_time: this.end,
      }).then(resp => {
        let {code,message} = resp;
        if (code === 200) {
          this.$emit('update:start', this.start);
          this.$emit('update:end', this.end);
          this.$emit('input', false);
          this.$message.success(message);
        } else {
          this.$message.error(message);
        }
      }).catch(e => {
        throw e;
      });
    },
    handleCancel() {
      this.$emit('input', false);
    }
  }
}
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
